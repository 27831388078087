@use './variables';

.modal {
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(10px);
  z-index: 10000;
  display: none;
  // overflow-y: scroll;
  .modal_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 70%;
    height: 95vh;
    width: 80%;
    padding: 24px;
    overflow-y: scroll;
    .close_modal {
      position: relative;
      margin-left: auto;
      cursor: pointer;
      width: fit-content;
      height: fit-content;
      margin-bottom: 20px;
    }
  }
}
.openModal {
  display: block;
}
@media screen and (max-width: 900px) {
  .modal {
    .modal_wrapper {
        width: 100%;
        height: 95%;
      }
  }
}
