@use './variables';

.secondary_two {
  p {
    color: variables.$secondary-color-two;
  }
}
.textMarginRight {
  margin-right: 80px;
}
.textMarginLeft {
  margin-left: 80px;
}
.customWidth {
  width: 500px;
}
.expertizaWidth{
  width: 670px;
}
.customContactWidth {
  width: 350px;
}

@media screen and (max-width: 1100px) {
  .textMarginRight {
    margin-right: initial;
  }
  .textMarginLeft {
    margin-left: initial;
  }
}

@media screen and (max-width: 768px) {
  .customWidth,
  .expertizaWidth{
    width: 100%;
  }
}
