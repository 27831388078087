@use './variables';

.header {
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: variables.$background-color;
  color: variables.$menu-color;
  box-shadow: 0px 1px 13px #fefefe34;
  -moz-box-shadow: 0px 1px 13px #fefefe34;
  -webkit-box-shadow: 0px 1px 13px #fefefe34;
  @include variables.flexProps(row, center, center);

  article {
    width: 100%;
    position: relative;
    height: 100%;
    max-width: variables.$max-width;
    @include variables.flexProps(row, space-between, center);

    .logo {
      z-index: 2000;
      margin-left: 5%;
    }
    .openMenu {
      transform: translateX(100%);
      transition: 0.5s ease-in-out;
    }
    nav {
      background-color: variables.$background-color;
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0px;
      transition: 0.5s ease-in-out;
      @include variables.flexProps(column, center, center);
      ul {
        @include variables.flexProps(column, center, center);
        li {
          margin: 20px 0;
          margin-left: initial;
          font-size: 22px;
        }
      }
    }
    .hamburger_menu {
      margin-right: 3%;
      @include variables.flexProps(row, center, center);
      display: block;
    }
  }
}

@media screen and (min-width: 900px) {
  .header > article {
    .logo {
      margin-left: initial;
    }
    .openMenu {
      transform: initial;
      transition: 0.5s ease-in-out;
    }
    width: 90%;
    max-width: 1200px;
    nav {
      position: relative;
      width: initial;
      height: initial;
      transform: initial;
      transition: 0.5s ease-in-out;
      ul {
        @include variables.flexProps(row, center, center);
        li {
          margin-left: 40px;
          font-size: 18px;
        }
        .ue_project {
          cursor: pointer;
        }
      }
    }
    .hamburger_menu {
      display: none;
    }
  }
}
