.image_wrapper {
  position: relative;
  width: 100%;
  .bg {
    border-radius: 12px;
  }
}
.heroSize {
  top:-50px;
  height: 680px;
  width:1000px;
}

.sectionSize {
  height: 400px;
}
.footerSize {
  width: 650px;
  height: 500px;
}

.legalSize {
  width: 146px;
  height: 37px;
}
.smallCardSize {
  width: 96%;
  height:280px;
  transition: 0.2s ease-out;
  border: 2px solid transparent;
  &:hover{
    transform: scale(1.05);
    transition: 0.2s ease-out;
  }
  &:hover img{
    transition: 0.2s ease-out;
    border: 2px solid white;
  }
}
.carouselImages{
  width: 96%;
}
.serviceCardSize{
  width: 100%;
  height: 100%;
}
.logoSize {
  width: 130px;
  height: 50px;
}
.heroShapes {
  width: 50vw;
  height: 1450px;
}
.contactShapes{
  width: 40vw;
  height: 850px;
}
.aboutShapes{
  width: 44vw;
  height: 750px;
}
.fullStretch {
  width: 100%;
  height: 600px;
}
.autoHeight {
  width: 200px;
}

@media screen and (max-width: 1500px) {
  .heroSize {
    top:-10px;
    height: 480px;
    width:700px;
  }
  .heroShapes {
    width: 40vw;
    height: 1450px;
  }
}
@media screen and (max-width: 1200px) {
  .heroSize {
    height: 320px;
    width: 470px;
  }
  .heroShapes,
  .aboutShapes {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .heroSize {
    width: 60vw;
    height: 400px;
  }
  .sectionSize {
    height: initial;
    width: 100%;
  }
  .footerSize {
    width: 100%;
    height: initial;
  }
}

@media screen and (max-width: 1400px) {
  .smallCardSize {
    height:220px;
    &:hover{
      transform: initial;
      transition: initial;
    }
  }
}
@media screen and (max-width: 1000px) {
  .smallCardSize {
    width: 100%;
    height: initial;
    &:hover{
      transform: initial;
      transition: initial;
    }
  }
    .heroSize{
      height: initial;
      width:calc(100vw - 40px);
  }
  .fullStretch {
    width: 100%;
    height: 300px;
  }
  .carouselImages{
    width: 100%;
    height: initial;
  }
}
@media screen and (max-width: 400px) {
  .heroSize {
    height: 50%;
    width: 350px;
 
  }
}