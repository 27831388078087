.card_text_over_image {
  position: relative;
  width: 390px;
  height: 507px;

  .overlay_bg{
    background-color: black;
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    color:white;
    z-index: 10;
    opacity: 0.5;
    border-radius: 12px;
    transition: 0.3s ease-in-out;

    &:hover{
      transition: 0.2s ease-in-out;
      opacity: 0;
    }
  }
}

@media screen and (max-width: 900px) {
  .card_text_over_image {
    position: relative;
    width: 100%;
    height: initial;
  }
}
