.children{
    position: absolute;
    top:0;
    left:0;
    z-index: 1;
}

@media screen and (max-width:900px) {
    .children{
        display: none;
    }
}